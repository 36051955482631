.ant-modal.allergens-modal {
  max-width: 400px;

  .ant-modal-content {
    box-sizing: border-box;
    padding: 24px;
  }

  .ant-modal-header {
    border: none;
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
    padding: 32px 0 32px 0;
  }

  .ant-modal-footer {
    padding: 16px 0 0 0;
  }

  .ant-btn:last-child {
    margin-left: 16px;
  }
}
