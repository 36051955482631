// Layout
@layout-body-background: #fff;
@layout-header-background: #fff;

@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-header-color: @text-color;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;

// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

@brand-sidenav-margin: 20px;
@brand-sidenav-height: 30px;

.ant-layout {
  min-height: 100vh;
}

.ant-layout .brand {
  margin: @brand-sidenav-margin;

  svg {
    width: 24px;
    height: @brand-sidenav-height;
  }
}

.ant-layout .brand-navbar {
  display: inline-block;

  svg {
    width: 32px;
    height: 32px;
  }
}

.ant-layout .logo {
  height: 64px;
  max-width: 64px;
  object-fit: contain;
}
