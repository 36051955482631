.product-arrangement-wrapper {
  > .container {
    max-height: 65vh;
    overflow-y: scroll;
    margin-bottom: 35px;

    .draggable-product-card {
      background-color: @disabled-bg;
      box-shadow: none;
    }
  }
}

.draggable-product-card {
  z-index: 9999 !important;
  background-color: #fafafb;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 0.5rem 0;
  font-size: 14px;
}
