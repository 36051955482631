// container of Base Select
.ant-select {
  color: @text-color;
  // nested container/borders
  &.ant-select:not(.ant-select-customize-input) > .ant-select-selector {
    border: 1px solid @background-color_75;
    padding: 2px 16px;
    border-radius: @border-radius-md;

    &:hover {
      border: 1px solid @secondary-color;
      box-shadow: @input-box-shadow--active;
    }
  }

  &.ant-select-single:not(.ant-select-customize-input) > .ant-select-selector {
    padding: 2px 16px;
    height: 36px;
    border-radius: @border-radius-md;

    &:hover {
      border: 1px solid @secondary-color;
      box-shadow: @input-box-shadow--active;
    }
  }

  > .ant-select-arrow {
    top: 50%;

    display: flex;
    align-items: center;
  }
}

.ant-select.ant-select-single.-error > .ant-select-selector {
  border: 1px solid @error-color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  > .ant-select-selector {
  border-color: @secondary-color;
  box-shadow: @input-box-shadow--active;
}

.ant-select-single > .ant-select-selector > .ant-select-selection-search {
  left: 16px;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

//  ANTD Select Dropdown (Options list)
.ant-select-dropdown {
  border: 1px solid @background-color_75;
  box-sizing: border-box;
  z-index: 9999;

  box-shadow: 0px 2px 4px rgba(7, 4, 146, 0.1),
    0px 24px 60px rgba(6, 47, 125, 0.05), 0px 12px 24px rgba(27, 59, 119, 0.05);
  border-radius: 4px;
}

.ant-select-clear {
  display: flex;
}

.select-multiple .ant-select-item-option {
  > .ant-select-item-option-content {
    padding-left: 32px;
  }
}

.ant-select-item-option-state {
  > span[role='img'].anticon {
    display: none;
  }
}

div[aria-selected='false'].ant-select-item-option-active,
div[aria-selected='true'].ant-select-item-option-selected {
  background-color: @secondary_10;
  border-radius: 4px;
}

.ant-select-multiple.ant-select.ant-select:not(.ant-select-customize-input).-with-icon {
  & > .ant-select-selector {
    padding: 4px 8px 4px 48px;
  }
}

.ant-select-item-option.divider {
  cursor: default;
  padding: 0;
  min-height: unset;

  > .ant-select-item-option-content > .ant-divider {
    margin: 4px 0;
  }
}

//order-dropdown is a custom class to reinforce style exclusivity
.ant-select-dropdown.order-dropdown {
  //needed to override the width since it originally sets a fixed with according to the height of its parent via style prop
  width: 240px !important;

  .ant-select-item-option-content {
    display: flex;
  }
  //order-count is a custom class to reinforce style exclusivity
  span.order-count {
    margin-left: auto;

    color: @text-color-secondary;
    font-weight: 300;
    text-align: right;
  }
}

// fix for the select component having a slightly higher height than its sibling select components
.ant-select[data-testid='order-status-filter-dropdown'].ant-select-multiple.ant-select.ant-select:not(.ant-select-customize-input).-with-icon {
  .ant-select-selector {
    padding-bottom: 2px;
  }
}

.ant-select[data-testid='order-location-filter-dropdown'].ant-select-multiple.ant-select.ant-select:not(.ant-select-customize-input).-with-icon {
  .ant-select-selector {
    padding-bottom: 2px;
  }
}

.ant-select.allergen-select {
  .ant-select-selection-overflow {
    display: flex;
    width: 100%;
  }

  .ant-select-selection-overflow-item > .ant-select-selection-item {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 8px;
    background: @background-color_50;

    color: @subtitle-color;
    border-radius: 100px;
    text-transform: initial;
    /* min-width: 88px; */

    span {
      display: block;

      max-width: 3rem;
      overflow: hidden;

      font-size: 0.75rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span[role='img'] {
      margin-left: 2px;
    }
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    position: relative;

    display: flex;
    justify-content: flex-end;
    flex-grow: 5;
    padding-left: 16px;
    margin-right: 8px;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest::before {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    content: '...';
    color: @text-color;
    font-size: 1rem;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
    > .ant-select-selection-item {
    background-color: @secondary-color;
    margin: 0;

    border: none;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
    > .ant-select-selection-item
    > .ant-select-selection-item-content {
    margin: 0;
    color: white;
    font-size: 0.75rem;
  }
}
