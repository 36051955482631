.rebook-subform{
  .buttons {
    display: flex;
    justify-content: end;
    gap: 12px;
  
    >* {
      min-width: '70px'
    }
  }
}
