.commission-rates-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.navigation-arrow {
  font-size: 2.5rem;
  cursor: pointer;
}
