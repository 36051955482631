.applied-discount {
  width: 100%;

  >.content {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: #389E0D1A;
    border: 1px solid #389E0D;

    >.wrapper {
      max-width: 80%;
      white-space: pre;
      padding: 12px;
      display: flex;
      align-items: center;

      >svg {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        fill: #59595A;
        flex-shrink: 0;
      }

      >.code {
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        flex-shrink: 0;
        margin-right: 4px;
      }

      >.description {
        color: #59595A;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;

      }
    }
  }
}