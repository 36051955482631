@draggable-icon-light: './../../../../less-icons/draggable-light.svg';
@reorder-offset-top: 80px;

.hide-collapse {
  display: none;
}

//overrides default margin when focusing in a single category
.opened-collapse {
  margin: 0 !important;
}

[data-testid='reorder-products-drawer'].reorder-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header {
  position: relative;

  background-color: @white;
  height: @reorder-offset-top;

  > .ant-drawer-title {
    color: @secondary-color;
  }
}

//the dark purple strip that serves as a container for save/discard buttons above the drawer body
.reorder-strip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  display: flex;
  height: 54px;
  background-color: @secondary-color;
  //overrides margin-right that causes overflow-x to the drawer
  margin-right: 0 !important;
}

.reorder-strip.reorder-buttons {
  z-index: 2250;
}

//reorder-drawer is a custom class to enforce style exclusivity
[data-testid='reorder-products-drawer'].reorder-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  padding: 0;
  overflow-y: hidden;
}

[data-testid='reorder-products-drawer'].reorder-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  position: relative;
}

[data-testid='reorder-products-drawer'].reorder-drawer {
  .ant-drawer-wrapper-body {
    overflow-x: hidden;
  }
}

//sortable-container is a custom class to enforce style exclusivity
.sortable-container {
  padding: 4px 16px 4px 0px;
  margin-top: 56px;
  margin-bottom: 0 !important;
  height: calc(100vh - 15rem);
  overflow-y: scroll;

  //Categories Card/Sortable item
  > div > .ant-card {
    position: relative;

    margin: 8px 0;
    background-color: @background-color_25;
    border-radius: @border-radius-md;
    opacity: 1;
  }

  > div > .ant-card:first-of-type {
    margin: 0 0 8px 0;
  }

  > div > .ant-card:last-child {
    margin: 8px 0 0 0;
  }

  //Categories card header bottom white border
  > div > .ant-card::before {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;

    content: '';
    height: 1px;
    background-color: white;

    pointer-events: none;
  }

  .ant-card.ant-card-bordered.width-100 .ant-card-body {
    padding: 8px 16px 8px 8px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  //category header -> category name, mind the ant-col-23 and ant-col-24 difference
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-row
    > .ant-col-23
    > .ant-row
    > .ant-col:first-of-type,
  .ant-collapse-item-active
    > .ant-collapse-header
    > .ant-row
    > .ant-col-24
    > .ant-row
    > .ant-col:first-of-type {
    font-size: 13px;
    color: @secondary-color;
  }

  //category header -> product count label, mind the ant-col-23 and ant-col-24 difference
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-row
    > .ant-col-23
    > .ant-row
    > .ant-col:last-child,
  .ant-collapse-item-active
    > .ant-collapse-header
    > .ant-row
    > .ant-col-24
    > .ant-row
    > .ant-col:last-child {
    font-size: 13px;
    color: @secondary-color;
    font-weight: 200;

    transform: translateY(1px);
  }

  //styling for the arrow icon on the right
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-arrow {
    right: -8px;

    max-width: 28px;
    max-height: 32px;
  }

  //changes the color of the arrow icon on the right
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-arrow
    > path {
    fill: @secondary_75;
  }

  // products list item
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 36px 0 16px 16px;
  }

  // variants list item
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box
    .ant-collapse-content
    .ant-collapse-content-box {
    padding: 20px 0 16px 16px;
  }

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box
    .ant-collapse-content
    .ant-collapse-content-box
    .ant-row {
    border-radius: 4px;
  }

  //item sorter
  .product-arrangement-wrapper > div {
    padding-right: 8px;
  }

  .product-arrangement-wrapper .ant-card.ant-card-bordered.width-100 {
    border-radius: 4px;
  }

  .product-arrangement-wrapper .ant-card.ant-card-bordered.width-100 > div {
    padding-bottom: 16px;
  }

  .product-arrangement-wrapper
    .ant-collapse-item.ant-collapse-item-disabled
    .ant-collapse-header {
    cursor: grab;
  }

  .product-arrangement-wrapper
    .ant-collapse-item.ant-collapse-item-disabled
    .ant-collapse-header
    .ant-col:first-of-type {
    color: @black;
  }

  .ant-collapse-content.ant-collapse-content-active
    > .ant-collapse-content-box {
    max-height: inherit;
  }

  .ant-collapse-content.ant-collapse-content-active
    > .ant-collapse-content-box
    > .product-arrangement-wrapper {
    max-height: inherit;
  }

  .ant-collapse-content.ant-collapse-content-active
    > .ant-collapse-content-box
    > .product-arrangement-wrapper
    > .container {
    max-height: calc(100vh - 392px);
    margin-bottom: 8px;
  }

  .ant-collapse-content.ant-collapse-content-active
    > .ant-collapse-content-box
    > .product-arrangement-wrapper
    > .container
    > div[data-testid='product-row-container'] {
    max-height: inherit;
  }
}

.sortable-container .ant-card.draggable-product-card {
  &:first-of-type {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.product-arrangement-wrapper {
  > .container > div > .ant-card {
    background-color: @white;
    box-shadow: 0px 1px 2px rgba(74, 74, 104, 0.1);
  }
}

//.sortable-category-item is a custom "active state" class, acts as a safeguard against the styles of other components
.ant-card.sortable-category-item.ant-card-bordered.width-100 {
  z-index: @z-index-sortable-category;
  border-radius: @border-radius-md;

  .ant-card-body {
    padding: 0px !important;
    max-height: 40px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  }

  //styling for the arrow icon on the right
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-arrow {
    right: 8px;

    max-width: 28px;
    max-height: 32px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header,
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 8px;
    padding-right: 40px;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-row
    > .ant-col-23
    > .ant-row
    > .ant-col:last-child,
  .ant-collapse-item-active
    > .ant-collapse-header
    > .ant-row
    > .ant-col-24
    > .ant-row
    > .ant-col:last-child {
    padding-right: 16px;
    font-size: 13px;
    color: @secondary-color;
    font-weight: 200;

    transform: translateY(1px);
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-row
    > .ant-col-23
    > .ant-row
    > .ant-col:first-of-type,
  .ant-collapse-item-active
    > .ant-collapse-header
    > .ant-row
    > .ant-col-24
    > .ant-row
    > .ant-col:first-of-type {
    font-size: 13px;
    color: @secondary-color;
  }
}

.ant-collapse.-product {
  border-radius: 20px;
  border: none;
}

.ant-collapse-item.-product {
  border-radius: 4px;
  background: @background-color_25;
  border: none;
  margin-bottom: 8px;
}

.ant-collapse-item.-product
  > .ant-collapse-content {
  border-top: none;
}

.ant-collapse-item.-product
  > .ant-collapse-content
  > .ant-collapse-content-box {
  background: @background-color_25;
}

.ant-collapse-item.-compact
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 0 16px 0;
}

.ant-collapse.-plain > .ant-collapse-item > .ant-collapse-header {
  color: @text-color;
  background-color: @white;
}

.ant-collapse.-borderless-edges {
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.draggable-icon-light {
  max-width: 10px;
  background-image: url(@draggable-icon-light);

  > .with-allergen {
    height: 44px;
  }

  > .without-allergen {
    height: 22px;
  }
}

.ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
  .ant-collapse-arrow {
    vertical-align: 1px;
  }
}

.sortable-category-item,
.sortable-item {
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-arrow {
    right: -8px;

    max-width: 28px;
    max-height: 32px;
  }
}

// current item being dragged
body > .ant-card.ant-card-bordered.width-100.sortable-item {
  border-radius: 4px;
  box-shadow: 0px 6px 12px rgba(12, 12, 12, 0.25);

  &
    > .ant-card-body
    > .ant-collapse[role='tablist']
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 0;
  }

  &
    > .ant-card-body
    > .ant-collapse[role='tablist']
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-row
    * {
    font-size: 13px;
  }

  &
    > .ant-card-body
    > .ant-collapse[role='tablist']
    > .ant-collapse-item
    > .ant-collapse-content.ant-collapse-content-active[role='tabpanel']
    > .ant-collapse-content-box {
    padding: 20px 8px 24px 16px;
  }

  &
    > .ant-card-body
    > .ant-collapse[role='tablist']
    > .ant-collapse-item
    > .ant-collapse-content.ant-collapse-content-active[role='tabpanel']
    > .ant-collapse-content-box
    .ant-row {
    border-radius: 4px;
  }

  .ant-collapse-item.ant-collapse-item-disabled
    .ant-collapse-header
    .ant-col:first-of-type {
    color: @black;
  }
}
