.select-prefix-wrapper {
  position: relative;

  display: flex;

  > .ant-select {
    max-width: 240px;

    color: @text-color;
    font-size: @font-size-base + 1;
    font-weight: 400;
  }

  > .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    width: inherit;
    padding-left: 48px;
    padding-right: 32px;
  }

  > .-icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 0;

    pointer-events: none;
  }

  > .-icon::after {
    position: absolute;
    right: 0;
    top: 50%;

    content: '';
    width: 1px;
    height: 80%;
    background-color: @background-color_50;

    transform: translateY(-50%);
  }

  > .-icon svg {
    width: 18px;
    height: 18px;
  }

  > .-icon svg path {
    fill: @subtitle-color;
  }
}
