.pricing-header {
  margin-top: 12px;
  
  &:first-of-type {
    margin-top: 52px;
  }

  >.title {
    color: #d092dd;
    text-transform: uppercase;
    font-weight: 700;
  }

  >.subtitle {
    display: flex;
    align-items: center;
    height: 30px;
    font-style: italic;
  }
}
