.circle-container {
  width: 24px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.container {
  background-color: #E7E5E9;
  padding: 8px 16px;
}

.category-name {
  align-items: center;
  display: flex;
  font-size: 15px;
}

.label {
  color: #D092DD;
}

.clear {
  height: 36px;
  background-color: #E7E5E9;
}