.actions-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @secondary-color;
  padding: 8px 16px;

  &.-compact {
    padding: 8px;
  }

  & > .title {
    color: @white;
  }
}
