.order-store-info {
  > .name {
    font-weight: bold;
    color: @primary-color;
    margin-bottom: 4px;
  }

  > .zip {
    color: @text-color;
    margin-bottom: 0;
  }
}
