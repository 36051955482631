.location-inventory-publisher-title {
  padding: 16px 24px 16px;
  font-size: 18px;
}

.location-inventory-publisher-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @secondary-color;
  padding: 8px 16px;
}

.location-inventory-publisher-drawer {
  > * > * > * > .ant-drawer-header-no-title {
    min-height: 110px;
  }

  .ant-drawer-body {
    max-height: calc(100vh - 108px);
    overflow-y: hidden;
    padding-right: 0;
  }

  .ant-tabs-content-holder {
    max-height: calc(100vh - 200px);
    padding-right: 16px;
  }
}


.location-inventory-publisher {
  .header {
    padding: 16px 0 16px 16px;
    background: @background-color_25;
  }
}
