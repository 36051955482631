.fallback-order-step {
  font-size: 24px;
  padding: 8px 0 24px;
  color: @warning-color;
  text-transform: uppercase;
  text-align: center;

  > .icon {
    font-size: 32px;
    margin-bottom: 4px;
  }
}
