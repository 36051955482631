// General InputNumber styling
.ant-input-number {
  position: relative;

  display: flex;
  align-items: center;
  border: 1px solid @background-color_75;
  height: 36px;
  border-radius: @border-radius-md;

  &:hover {
    border-color: @secondary-color;
    box-shadow: @input-box-shadow--active;
  }
}

.ant-input-number-focused {
  border-color: @secondary-color;
  box-shadow: @input-box-shadow--active;

  .ant-input-number-input-wrap {
    width: 100%;
  }
}

.ant-input-number.compact {
  height: 25px;
}

.ant-input-number-input {
  border: 0;
}

// Symbol/Measurement
.text-field-number-addon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: calc(100% - 2px);
  max-height: 37px;
  background-color: transparent;
  border: 0 !important;
  box-shadow: none;

  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%);
}

// Custom InputNumber Component Styling
.text-field-number-input.ant-input-group {
  display: inline-table;

  &:hover {
    .text-field-number-addon {
      right: 36px;

      transition: all 25ms 25ms ease-in;
    }
  }

  // Input Spinners(Container)
  .ant-input-number-handler-wrap {
    top: 50%;
    right: 0;

    width: 36px;
    height: 100%;
    transform: translateY(-50%);
    border-left: 1px solid @background-color_50;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px @border-radius-md @border-radius-md 0;
    overflow: hidden;

    transition: all 25ms;
  }

  // Input Spinners(Up & Down arrows)
  .ant-input-number-handler {
    background-color: @background-color_25;

    & > span {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      border: 0;
    }

    svg path {
      fill: #8c8c8c;
    }

    .ant-input-number-handler-down-inner {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // Input Container
  .ant-input-number-input-wrap {
    width: 100%;

    // Input Container itself
    .ant-input-number-input {
      width: inherit;
      padding: 0 72px 0 16px;
    }
  }
}

.ant-input-number-handler-down {
  border: 0;
}

// Just add a inputType="number" property in the TextFieldNumber component
.text-field-number-input.ant-input-group.-number {
  > .ant-input-number {
    width: 72px;
  }

  > .ant-input-number > .ant-input-number-handler-wrap {
    opacity: 1;
    width: 28px;
  }

  > .ant-input-number > .ant-input-number-input-wrap > .ant-input-number-input {
    padding: 0 38px 0 8px;

    color: @text-color;
    font-weight: 400;
    line-height: 1;
    text-align: right;
  }

  // Disabled state
  > .ant-input-number-disabled {
    width: 40px;
  }

  > .ant-input-number > .ant-input-number-input-wrap > .ant-input-number-input {
    padding: 0 36px 0 0;
  }

  > .ant-input-number-disabled:hover {
    box-shadow: none;
    border: 1px solid @background-color_75;
  }

  > .ant-input-number-disabled > .ant-input-number-input-wrap > input[disabled] {
    padding: 0 8px;
    color: @disabled-color;
  }

  > .ant-input-number-disabled > .ant-input-number-handler-wrap {
    opacity: 1;
  }
}
