.pricing-table {
  border: 2px solid #E7E5E9;
  border-radius: 12px;
  margin: 0 18px;

  >.header {
    display: flex;
    flex-flow: column;
    justify-content: center;

    font-size: 14px;
    height: 60px;

    >.title {
      color: #d092dd;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
    }

    >.subtitle {
      font-style: italic;
      display: flex;
      justify-content: space-around;
    }
  }

  >.cell {
    border-top: 2px solid #E7E5E9;
    padding: 18px 0 6px 0;

    >.row {
      height: 32px;
      margin: 0;

      &:nth-child(2n) {
        border-top: 1px solid #E7E5E9;
      }
    }
  }
}
