.inventory-changes .ant-row.ant-row-center.list-item {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: @background-color_25;
    width: 100%;
    font-size: 13px;
    line-height: 1.5;
    color: @secondary-color;
    transition: background 150ms ease-in;

    span {
        max-width: 32ch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &:hover {
        background-color: @background-color_50;
    }
}

.inventory-changes {
    .ant-modal-content {
        min-width: 720px;
        transform: translateX(-7.5vw);
    }

    .ant-tabs-content-holder {
        min-height: 200px;
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .ant-row.-title {
        position: sticky;
        top: 0;
        left: 0;

        padding: 4px 8px;
        background-color: white;
        font-size: 14px;
        font-weight: 500;
    }

    .ant-row.-transparent {
        opacity: 0.4;
    }

    .ant-modal-body {
        padding-top: 16px;
    }
}