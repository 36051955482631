.order-actions-container {
  background: @secondary-color;
  padding: 16px;

  .order-actions {
    margin-bottom: 0;
  
    .transaction {
      color: @white;
      font-size: 24px;
      font-weight: bold;
    }
  
    .transaction > .id {
      color: @primary-color;
    }
  
    .print {
      margin-left: 16px;
      margin-right: auto;
    }
  }

  .delivery-status {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
  }
}