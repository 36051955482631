.overflow-container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  margin: 2px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
}