.slerp-optimze-delivery-pricing {
  font-family: 'Poppins';
  background: @background-color_10;
  padding: 24px;
  border-radius: 4px;

  > .title {
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 0;
    color: @black;
    padding-bottom: 14px;
    font-weight: 600;
    font-size: 14px !important;
  }

  > .message {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: @secondary_75;
  }

  > a .template {
    padding: 8px;
    margin-bottom: 0 !important;
    border-radius: 4px;
    background: @component-background;
    box-shadow: 0px 2px 3px rgba(90, 95, 165, 0.2);
    cursor: pointer;

    > .first-column {
      display: flex;
      justify-content: flex-start;
      align-items: center;


      > .paper-clip {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;
        width: 28px;
        height: 28px;
        background: #D092DD;
        border-radius: 4px;
  
        > .icon {
          color: @background-color_10;
        }
      }

      > .file-name {
        margin-left: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: @secondary-color;
        cursor: pointer;
      }
    }

    > .second-column {
      display: flex;
      justify-content: center;
      align-items: center;

      > .activated {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #4EB31C;
  
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border: 1px solid #DBD9DF;
        border-radius: 4px;
        padding: 1px 16px;
      }

      
    }
  }
}