.marketing-initiative-modal {
  .ant-carousel .slick-dots li button {
    background: @secondary-color;
    opacity: 0.4;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: @secondary-color;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 0;
  }

  .ant-modal-footer {
    display: flex;
    text-align: justify;
    justify-content: space-between;
  }

  .carousel-item {
    text-align: center;
    padding: 1em 2em 0 2em;

    // had to use !important here, there is some sort of default inline style with the container/carousel-item
    // that sets the display to inline-block, which prevents any external CSS to change the display prop
    display: flex !important;
    flex-flow: column;
    align-items: center;

    .carousel-caption {
      font-weight: 700;
      font-size: 24px;
    }

    .carousel-subcaption {
      font-size: 20px;
      width: 36ch;

      font-weight: 300;
    }

    .carousel-screen {
      margin-left: auto;
      margin-right: auto;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    .small-carousel-image {
      height: 240px;
    }

    .carousel-cta-button {
      cursor: pointer;
      > span {
        text-transform: uppercase;
      }
    }

    .dates-list {
      .check-icon {
        height: 20px;
        width: 20px;
      }

      .date-list-item {
        font-size: 22px;
      }
    }
  }
}
