.loyalty-qr-modal {
  > * > .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }

  > * > .ant-modal-header {
    background: @secondary-color;
  }
}

.qr-modal-header {
  display: flex;
  justify-content: space-between;

  > .title {
    margin: 0;
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  > .context {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: @white;
  }

  > .context > .name {
    color: @primary-color;
    font-size: 12px;
    margin: 0;
    margin-left: 8px;
  }
}

.loyalty-qr {
  display: flex;
  justify-content: center;
}
