.ant-statistic-title {
  font-weight: bold;
  color: #BABBC0;
}

.ant-statistic {
  > .ant-statistic-content > .ant-statistic-content-value {
    color: @text-color;
  }

  > .ant-statistic-content > .ant-statistic-content-value > .ant-statistic-content-value-int {
    font-size: 30px;
  }

  > .ant-statistic-content > .ant-statistic-content-value > .ant-statistic-content-value-decimal {
    font-size: 20px;
  }

  > .ant-statistic-content > .ant-statistic-content-prefix {
    font-size: 16px;
  }
}