.version-banner-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 250;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;

  &.-hidden {
    display: none;
  }
}

.version-banner {
  cursor: pointer;
  transition: all 100ms ease-in-out;
  width: max-content;
  max-width: 90vw;
  padding: 10px 16px 8px 18px;
  background-color: white;
  color: @text-color;
  font-size: @font-size-sm;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  &.-hidden {
    transform: translateY(60%);
  }

  > .text {
    text-overflow: 'clip';
    word-break: 'break-all';
    display: 'inline-block';
    letter-spacing: '0.01em';
  }
  
  > .icon {
    color: @primary-color;
    margin-left: 8px;
  }
}