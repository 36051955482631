main.site-content.-main.-dashboard {
  background: transparent;
  padding: 0 24px 0 47px !important;
  margin-right: 24px;
  margin-left: 0;
}

section.site-layout {
  background: @background-color_25;
  overflow-x: scroll;
}

.site-layout {
  background: @background-color_25;
  // height: 100vh;
}

.site-content.-main {
  width: auto;
  // overflow-y: scroll;
  border-radius: 4px;
}