.product-pricing {
  > .header {
    padding: 16px 0 16px 16px;
    background: @background-color_25;
  }
}

.product-pricings-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @secondary-color;
  padding: 8px 16px;
}
