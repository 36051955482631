.delivery-buttons {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  justify-content: space-around;
            
  >.button {
    font-size: 12px;
  }
}
