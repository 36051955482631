.fulfillment-type-badge {
  padding: 6px 16px;
  border: 1px solid black;
  border-radius: 40px;
  margin-left: 12px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: @font-size-sm;

  &.-green {
    color: #4eb21c;
    border-color: #4eb21c;
  }

  &.-orange {
    color: #f38a19;
    border-color: #f38a19;
  }

  &.-gray {
    color: gray;
    border-color: gray;
  }
}
