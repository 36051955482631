.ant-input.stripe-input {
    border-radius: 4px;

    & > div {
        width: 100%;
    }
}

.ant-row > .ant-input.stripe-input {
    max-width: calc(50% - 8px);
}

.ant-input.card-name {
    border-radius: 4px;
}

span.add-card {
    font-weight: 500;
    color: @secondary-color;
}

.ant-modal.card-modal {
    max-width: 400px;

    .ant-modal-body {
        padding: 40px 64px;
    }

    .ant-modal-footer {
        display: flex;
        justify-content: center;

        border-top: 0px;
        padding-bottom: 40px;
        padding-top: 0;
    }
}