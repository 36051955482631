.meta-description-container > .ant-modal-content {
	border-radius: 8px;
	padding: 40px 40px 24px 40px;
	min-width: 670px;

	.ant-modal-body {
		padding: 0px;
		font-size: 16px;
	}

	.ant-modal-footer {
		padding: 20px 0px 0px 0px;
	}

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 40px;
	}

	ol {
		padding-left: 25px;
	}
}