.print-order-container {
  min-height: 100vh;
  background-color: @white;

  .panel {
    max-width: 960px;
    margin: 0 auto;
    padding: 36px 0;
  }

  .-vat-figure {
    font-size: 11px;
  }
}
