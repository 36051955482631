.delivery-zone {
  max-width: 582px;

  .item {
    margin: 0;

    &.-hidden {
      display: none;
      visibility: hidden;
    }

    .row {
      min-height: 32px;

      .checkbox {
        cursor: default;
        pointer-events: none;
      }
    }

    .radio {
      margin-bottom: 8px;
    }

    .slider {
      margin: 0 20px 0 0;
      max-width: 480px;
    }
  }

  .map {
    margin: 0;
    position: relative;

    .tag {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      padding: 1px 16px;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      background: #F6FFED;
      color: #4EB21C;
      font-size: 12px;
    }
  }

  > .popup {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1513A0;
    margin-bottom: 8px;
    background: #E9E8FC;
    border: 1px solid #1513A0;
    border-radius: 8px;

    &.-warning {
      color: #F38A19;
      background: #FFF7E6;
      border-color: #F38A19;
    }
  }
}