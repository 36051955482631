.campaign-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: @secondary-color;
  padding: 16px 32px;

  > .discard {
    margin-right: 8px;
  }
}
