.order-type {
  align-items: center;
  margin-bottom: 8px;

  > .card {
    display: inline-flex;
    min-width: 350px;
    flex-direction: row;
    padding: 16px;
    border: 1px solid @background-color_75;
    border-radius: 4px;
    margin: 0 0 0 16px;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    > p {
      color: @secondary-color;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0;
    }

    > span {
      color: @secondary_75;
      text-transform: none;
    }
  }

  .icon-container {
    display: flex;
    padding: 8px 0 0 0;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: @secondary-color;

    svg {
      width: 24px;
      height: 24px;
      color: @component-background;
    }

    &.selection {
      background: @primary-color;
    }
  }
}

.order-type > .card {
  cursor: pointer;

  &.-active {
    box-shadow: @input-box-shadow--active;
    border: 1px solid @secondary-color;
  }
}
