.ant-btn-group.ant-dropdown-button {
  height: 36px;
  border-radius: @border-radius-md;

  .anticon {
    color: @text-color-secondary;
  }

  > .ant-btn {
    height: inherit;
    background: white;
    padding-right: 4px;
    border-color: @background-color_75;

    color: @secondary-color;
    font-weight: 400;
  }

  > .ant-dropdown-trigger {
    height: inherit;
    background: white;
    border-color: @background-color_75;
  }

  > .ant-btn.ant-btn-default:not(:last-child) {
    border-radius: @border-radius-md 0 0 @border-radius-md;
  }

  > .ant-btn.ant-dropdown-trigger:not(:first-of-type) {
    border-radius: 0 @border-radius-md @border-radius-md 0;
  }

  > .ant-dropdown-trigger > span[role="img"] {
    transition: all 300ms;
  }

  .ant-btn:first-of-type:not(:last-child) {
    border-right: none;
  }

  .ant-btn:last-child:not(:first-of-type) {
    border-left: none;
  }

  &:hover {
    .ant-btn {
      border-color: @background-color;
    }

    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
      border-color: @background-color_50;
    }
  }

  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    border-color: @background-color_50;
    color: @background-color;
    background: @component-background;

    .anticon {
      color: @background-color;
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    &.-plain {
      padding: initial
    }
  }

  .ant-dropdown-menu {
    padding: 16px;
    filter: drop-shadow(0px 2px 4px rgba(7, 4, 146, 0.1)), drop-shadow(0px 24px 60px rgba(6, 47, 125, 0.05)), drop-shadow(0px 12px 24px rgba(27, 59, 119, 0.05));
    border-radius: 4px;
  }

  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    padding: 8px 12px;
    border-radius: 4px;
    position: relative;

    &:hover {
      background: @secondary_10;

      &::before {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: @secondary-color;
        border-radius: 4px 0 0 4px;
        transition: all 0.3s;
      }
    }
  }
}
