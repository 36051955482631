.location-inventory-pricings-title {
  padding: 16px 24px 16px;
  font-size: 18px;
}

.location-inventory-pricings-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @secondary-color;
  padding: 8px 16px;
}

.location-inventory-pricings-drawer {
  > * > * > * > .ant-drawer-header-no-title {
    min-height: 110px;
  }
}

.location-inventory-pricings {
  .header {
    padding: 16px 0 16px 16px;
    background: @background-color_25;
  }
}
