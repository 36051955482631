.table-sorter {
    svg > path {
        fill: #bfbfbf;
    }
}

.table-sorter.active {
    svg > path {
        fill: @primary-color;
    }
}

.custom-sorter {
    min-width: max-content;
}