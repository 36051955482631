.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border-color: @background-color_75;
  }

  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      border-color: @secondary-color;
      background-color: @component-background;

      &::after {
        border: none;
        transform: none;
        width: 8px;
        height: 8px;
        border-radius: 1px;
        background: @secondary-color;
        top: 3px;
        left: 3px;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      background: @secondary-color;
      height: 2px;
      top: 6px;
      left: 3px;
      transform: none;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: @secondary-color;
    }
  }

  .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @secondary-color;
  }

  .ant-checkbox-checked::after {
    border-color: @secondary-color;
  }
}

.ant-checkbox-group.-vertical {
  display: inline-grid;
  margin-right: 0;
}

.ant-checkbox-wrapper.day {
  position: relative;
}

.ant-checkbox-wrapper.day::after {
  position: absolute;
  top: -35%;
  left: -50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  content: '';
  color: #222222;
  border-radius: 50%;
  background-color: @background-color_50;

  // border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // touch-action: manipulation;
}

.ant-checkbox-wrapper.day:hover::after {
  background-color: @secondary-color;
  color: white;
}

.ant-checkbox-wrapper.day:checked::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 @primary-color;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s @ease-out-circ, waveEffect 0.4s @ease-out-circ;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 @primary-color;
    box-shadow: 0 0 0 @wave-animation-width var(--antd-wave-shadow-color);
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.ant-checkbox-wrapper-checked.day::after {
  background-color: @secondary-color;
  color: white;
}

.ant-checkbox-wrapper.day.-monday::after {
  content: 'M'
}

.ant-checkbox-wrapper.day.-tuesday::after {
  content: 'T'
}

.ant-checkbox-wrapper.day.-wednesday::after {
  content: 'W'
}

.ant-checkbox-wrapper.day.-thursday::after {
  content: 'T'
}

.ant-checkbox-wrapper.day.-friday::after {
  content: 'F'
}

.ant-checkbox-wrapper.day.-saturday::after {
  content: 'S'
}

.ant-checkbox-wrapper.day.-sunday::after {
  content: 'S'
}

