.onboarding-form {
  max-width: 756px;
}

.onboarding-logo {
  width: 24px;
  height: 32px;
  align-self: center;

  margin-top: 32px;
}

.onboarding-page {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.onboarding-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  background-color: @background-color_25;
  padding-bottom: 80px;

  div.ant-form-item-explain.ant-form-item-explain-error {
    margin: 4px 0 0 0;
    min-height: 8px;
  }

  .ant-form-item-label label {
    font-size: 0.875rem;
  }
}

.onboarding-panels.ant-collapse {
  min-width: 756px;
  background-color: transparent;
  border: 0;

  /* Whole panel */
  .ant-collapse-item.ant-collapse-no-arrow {
    border: 0;
    border-radius: 24px;
    background-color: white;

    margin-bottom: 40px;
  }

  /* Panel header */
  .ant-collapse-item.ant-collapse-no-arrow div.ant-collapse-header {
    padding: 2rem 3rem;
    overflow: visible;
    z-index: 50;
  }

  /* Panel body */
  .ant-collapse-content {
    border: 0;
    border-radius: 0 0 24px 24px;
  }

  .ant-collapse-content .ant-collapse-content-box {
    padding: 3rem 3rem 2rem 3rem;
  }

  .ant-collapse-item:nth-child(2)
    .ant-collapse-content
    .ant-collapse-content-box,
  .ant-collapse-item:nth-child(3)
    .ant-collapse-content
    .ant-collapse-content-box,
  .ant-collapse-item:nth-child(4)
    .ant-collapse-content
    .ant-collapse-content-box {
    padding: 0 3rem 2rem 3rem;
  }

  .ant-collapse-item:nth-child(5)
    .ant-collapse-content
    .ant-collapse-content-box {
    padding: 2rem 3rem;
  }
}

.onboarding-header {
  .onboarding-title {
    position: relative;
  }

  .onboarding-title h2 {
    font-size: 1.75rem;
    font-weight: 700;
    color: @secondary-color;
  }

  .onboarding-title.-disabled h2 {
    color: @secondary_25;
  }

  .onboarding-subtitle {
    position: absolute;
    top: 56px;

    line-height: 27px;
    opacity: 0;
  }

  .onboarding-subtitle h5 {
    display: block;
    color: @secondary_75;
    font-weight: 500;
    font-size: 1.125rem;
  }
}

.ant-collapse-item.ant-collapse-item-active.ant-collapse-no-arrow
  .onboarding-subtitle {
  animation: reposition 150ms ease-in-out forwards;
}

/* slug input */
.onboarding-slug {
  padding: 0;

  span.ant-input-prefix::after,
  span.ant-input-suffix::before {
    position: absolute;
    z-index: 20;

    display: block;
    width: 0.909px;
    background-color: #dbd9df;
    height: 100%;
    content: '';
  }

  span.ant-input-prefix,
  span.ant-input-suffix {
    position: relative;
    display: inline-flex;

    padding: 8px 16px;
    background: #f3f2f4;
    color: #cfccd4;
    border-color: #dbd9df;
    border-radius: 4px;
  }

  input#slug.ant-input[type='text'] {
    padding-left: 12px;
  }

  span.ant-input-prefix::after {
    right: 0;
  }

  span.ant-input-suffix::before {
    left: 0;
  }
}

.onboarding-optional {
  display: inline-flex;

  font-style: italic;
  color: #8b8b8b;
  font-size: 0.75rem;
  line-height: 1.5;
}

@keyframes reposition {
  from {
    top: 56px;
    opacity: 0;
  }

  to {
    top: 44px;
    opacity: 1;
  }
}

.onboarding-inputnumber {
  .ant-input-number-input {
    text-align: right;
    padding-right: 28px;
  }
}

.onboarding-inputnumber.onboarding-average-order-value {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    content: '£';
    height: 16px;
    width: 16px;
    transform: translateY(-50%) scale(0.9);
    font-weight: 600;
    border: 1px solid @secondary-color;
    color: @secondary-color;
    border-radius: 50%;
  }
}
