.courier-settings-submit {
  text-align: right;
}

.courier-form-header {
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @background-color_10;
}

