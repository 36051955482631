.main-header {
  box-shadow: 0px 1px 2px rgba(74, 74, 104, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 56px;
  padding: 0 48px 0 48px;
  
  line-height: 56px;
  font-size: 0.75rem;

  @media(min-width: 1366px) {
    height: 64px;
    line-height: 64px;
  }
}

.location-link {
  text-decoration: underline;
  margin: 0 0 0 20px;

  &:hover {
    color: @primary-color;
  }
}

a.location-link.disabled {
  color: @background-color_75;
  text-decoration: underline;
  pointer-events: none;

  &:hover {
    text-decoration: underline;
  }
}

.user-notifications {
  display: flex;
  align-items: center;

  .notifications {
    display: inline-flex;
    align-items: center;

    svg {
      width: 28px;
      height: 28px;
      color: @text-color-secondary;
      cursor: pointer;
    }

    &:after {
      content: '';
      width: 1px;
      height: 35px;
      background: @background-color_50;
      margin: 0 0 0 16px;
    }
  }
}

.user-block {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  div {
    line-height: 18px;
    padding: 0 10px;
  }

  div p {
    margin: 0 0 2px 0;
    font-size: 0.75rem;
  }

  div span {
    color: @text-color-secondary;
  }

  /* Avatar icon circle container */
  .ant-avatar.ant-avatar-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 32px;
    max-width: 32px;
    height: 32px;
    line-height: 1;
  }

  /* Circled avatar */
  .ant-avatar.ant-avatar-icon span.anticon.anticon-user[role='img'] svg {
    width: 20px;
    height: 20px;
  }

  svg {
    width: 20px;
    align-self: flex-start;
  }

  @media(min-width: 1366px) {

    div {
      line-height: 18px;
      padding: 0 10px;
    }

    div p {
      margin: 0 0 2px 0;
      font-size: 0.825rem;
    }

    div span {
      color: @text-color-secondary;
    }
  
    svg {
      width: 24px;
      align-self: flex-start;
    }

    /* Avatar icon circle container */
    .ant-avatar.ant-avatar-icon {
      min-width: 40px;
      max-width: 40px;
      height: 40px;
    }

    /* Avatar icon */
    .ant-avatar.ant-avatar-icon span.anticon.anticon-user[role='img'] svg {
      width: 24px;
      height: 24px;
    }
  }
}
