.customer-rewards-info {
  > .title {
    color: @primary-color;
    font-weight: bold;
  }

  > * > * > .name {
    color: @text-color;
    margin-bottom: 4px;
  }

  > * > * > .datetime {
    color: @subtitle-color;
    margin-bottom: 0;
  }
}
