.sidebar-nav {
  position: fixed;
  height: calc(100% - (@brand-sidenav-margin * 2) - @brand-sidenav-height);
}

.ant-layout-sider {
  height: 100vh;
  position: fixed;

  .anticon {
    width: 24px;
    height: 24px;
  }
}

.ant-layout-sider-children {
  .anticon {
    width: 24px;
    height: 24px;
  }

  .ant-menu {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .ant-menu-inline-collapsed .anticon {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child), 
  .ant-menu-vertical-left .ant-menu-item:not(:last-child), 
  .ant-menu-vertical-right .ant-menu-item:not(:last-child), 
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin: 0;
  }

  .ant-menu-vertical .ant-menu-item, 
  .ant-menu-vertical-left .ant-menu-item, 
  .ant-menu-vertical-right .ant-menu-item, 
  .ant-menu-inline .ant-menu-item {
    padding: 0 20px;
    min-height: 40px;
  }

  .ant-menu-item {
    padding: 0;

    a {
      display: flex;
      align-items: center;

      &:hover {
        svg {
          color: @primary-color;
        }
      }
    }
  }

  .ant-menu-item .anticon {
    text-align: center;
    min-width: 24px;
  }

  .ant-menu-item-selected::after {
    opacity: 1;
    transform: scaleY(1);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: @white;

    &:after {
      position: absolute;
      left: 0;
      right: inherit;
      border-color: @secondary-color;
    }
  }

  svg {
    color: @secondary_75;
  }

  .ant-menu-item-selected a {
    color: @secondary-color;

    svg {
      color: @secondary-color;
    }

    &:hover {
      color: @primary-color;

      svg {
        color: @primary-color;
      }
    }
  }
}

.sidebar-help {
  margin-top: auto !important;
}
