.draggable-item {
  background-color: @background-color_10;
  border: 1px solid @background-color_25;
  border-radius: @border-radius-md;

  max-width: 456px;

  //styling for the arrow icon on the right
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-arrow {
    top: 50%;

    max-width: 28px;
    max-height: 32px;
  }

  //changes the color of the arrow icon on the right
  .collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-arrow
    > path {
    fill: @secondary_75;
  }

  > .ant-card-body {
    padding: 0;

    .draggable-title {
      max-width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    display: flex;
    align-items: center;

    padding: 18px 24px 18px 18px;
    min-height: 64px;
    max-height: 64px;
  }
}

.draggable-item
  .ant-upload-picture-card-wrapper.avatar-uploader
  > .ant-upload-select {
  width: 100%;
}

.draggable-item .ant-upload > .ant-avatar {
  width: 100%;
  height: 100%;
}

.draggable-item .ant-upload > .ant-avatar > img {
  object-fit: contain;
}

.draggable-icon {
  cursor: grab;
}

.draggable-item {
  .-active {
    max-height: none;
  }

  .-inactive {
    max-height: 48px;
    min-height: 48px;
  }
}

.draggable-content {
  padding: 0 48px 0 24px;
  margin-top: -56px;
}
