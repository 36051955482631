.rebooking-form {
  >.reasons{
    &.ant-form-item-has-error {
      >.ant-form-item-label >.ant-form-item-required {
        color: #ff4d4f;
      }
    }

    .ant-form-item-explain {
      position: absolute;
      top: -28px;
      right: 5%;
      font-size: 12px;
    }
  }
}