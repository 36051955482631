.delivery-pricing-band-row {
  .ant-input-number.-error {
    border-color: @error-color;
  }

  .ant-input-group-wrapper.-error {
    input[data-testid='pricing-band-row-discount-field'],
    input[data-testid='pricing-band-row-from-field'],
    input[data-testid='pricing-band-row-to-field']
    {
      border-color: @error-color;
    }

    .ant-input-affix-wrapper {
      border-color: @error-color;
    }
  }

  .ant-input-group-wrapper.upperThreshold {
    .ant-input[type='number']:not(:disabled) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .ant-dropdown-trigger.upperThreshold {
    padding: 7px 4px;
    border: 1px solid @background-color_75;
    border-radius: @border-radius-md;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    &.error {
      border-color: @error-color;
    }
  }

  .upwards-upper-threshold {
    cursor: pointer;
  }
}
