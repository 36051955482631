.stripe-connect-card {
  width: 100%;
  box-shadow: inset 0 0 0 1px #8883;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  min-height: 96px;
  background-color: @secondary-color;
  font-size: 12px;
  color: @white;
  display: flex;
  justify-content: space-between;
  > .icon {
    width: 100px;
    margin-top: -20px;
  }

  .container {
    background-color: inherit;

    > .subtext {
      padding-bottom: 15px;
    }
    > .ant-btn > .btntext {
      font-size: 12px;
    }
  }
}
