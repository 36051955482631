.csv-upload {
  display: flex;
  position: relative;

  > .file > .ant-upload-list-text {
    position: absolute;
    left: 0;
    top: 32px;
    width: 120px;
  }
}
