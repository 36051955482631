.orders-form-item {
  padding-right: 8px;

  &:not(:first-of-type) {
    padding-left: 0 !important;
  }

  > .control {
    width: 100% !important;
  }
}
