.mb-1rem {
  margin-bottom: 1rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.sortable-item {
  z-index: 9999 !important;
}

.modifiers-arrangements {
  max-height: 65vh;
  overflow-y: scroll;
  margin-bottom: 60px;
}

.width-100 {
  width: 100%;
}

.pl-half-rem {
  padding-left: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}
