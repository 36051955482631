.product-overview {
  height: 100%;

  > div {
      min-width: 100%;
  }

  > .ant-table-wrapper {
    height: calc(100% - 44px);
  }

  > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-pagination {
    padding-right: 16px;
  }

  .ant-table-wrapper > .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.product-overview .ant-table .product-table.-header {
  background-color: transparent;
}
