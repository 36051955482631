.container-style {
  background-color: white;
  box-shadow: 0px 1px 2px rgba(74, 74, 104, 0.1);
  border-radius: 4px;
}

.analytics__heat-map {
  display: flex;
  justify-content: flex-end;
  border: none;
  border-radius: 4px;
  width: 100%;
  min-width: 200px;
  max-width: 280px;
  padding: 4px;
  background-color: @secondary-color;

  > label {
    width: 100%;
    text-align: center;
  }

  > label.ant-radio-button-wrapper .ant-col {
    margin-top: 1px;
  }

  > label.ant-radio-button-wrapper {
    background: transparent;
    border-radius: 4px;
    outline: none;
    border: none;
    box-shadow: 0px 1px 2px rgba(74, 74, 104, 0.1);

    color: @white;
    font-weight: 400;
  }

  > label.ant-radio-button-wrapper:hover {
    background: transparent;
  }

  > label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    outline: none;
    border: none;
    background-color: @white;
    box-shadow: 0px 1px 2px rgba(74, 74, 104, 0.1);
    color: @text-color;
  }

  > label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
    background-color: @white;
  }

  // hides the border between the radio buttons
  & .ant-radio-button-wrapper:not(:first-of-type)::before {
    display: none;
  }
}

.stats-component__select {
  box-shadow: 0px 1px 2px rgba(216, 224, 234, 0.5);

  &:nth-child(1) {
    width: 100%;
    max-width: 240px;
  }

  &:nth-child(2) {
    width: 100%;
    max-width: 160px;
  }

  > div {
    border-radius: 4px !important;
  }
}

ul.breakdown-legend {
  transform: translateY(-15px);
  padding-top: 10px;
  padding-left: 0;
  list-style: none;
}

span.breakdown-legend-indicator {
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 3px;
  margin-right: 10px;
}

svg > g > g:last-child {
  pointer-events: none;
}

@media (min-width: 1366px) {
  .stat-breakdown rect {
    width: 200px;
    height: 200px;
  }
}
