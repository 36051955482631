.category-panel {
  border-radius: 4px;
  padding: 12px 16px;
  background-color: @background-color_25;
  color: #000000;
  margin-bottom: 8px;

  &:last-child {
    margin: 0;
  }
}
