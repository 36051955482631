@order-drawer-width: 720px; 

// combination of order steps and order actions height
@order-drawer-header-height: 174px;

.location-inventory-publisher-drawer,
.location-inventory-pricings-drawer,
.order-drawer,
.app-settings-drawer,
.notification-drawer,
.loyalty-bonus-drawer {
  > .ant-drawer-content-wrapper {
    width: @order-drawer-width !important;
  }

  // not sure of a better way to do this
  > * > * > * > .ant-drawer-header-no-title {
    min-height: @order-drawer-header-height;
  }
}

.loyalty-bonus-drawer {
  > * > * > * > .ant-drawer-body {
    padding: 0;
  }

  // not sure of a better way to do this
  > * > * > * > .ant-drawer-header-no-title {
    min-height: 54px;
  }
}
