@order-header-z-index: 1;

.location-inventory-publisher-header,
.location-inventory-pricings-header,
.order-header,
.campaign-notif-header,
.loyalty-bonus-header {
  position: fixed;
  width: @order-drawer-width;
  right: 0;
  top: 0;
  z-index: @order-header-z-index;
}
