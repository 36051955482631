.stripe-alert.ant-alert {
  position: absolute;
  top: 80px;
  right: 48px;

  background-color: @secondary-color;
  width: 100%;
  max-width: 520px;
  border-radius: 4px;

  padding: 14px 16px;

  animation: fade-in 250ms ease-in forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .ant-alert-message {
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
  }

  .ant-alert-message > div > div:first-of-type {
    max-width: 40ch;
    line-height: 140%;
  }

  .ant-avatar {
    background-color: @primary-color;
    width: 42px;
    height: 42px;
    margin-right: 20px;
    margin-top: 2px;
  }

  .ant-avatar > .warning-icon {
    position: relative;
    top: 2px;
  }

  .ant-avatar > .warning-icon > svg {
    width: 20px;
    height: 20px;
  }

  .ant-avatar > .warning-icon > svg > path {
    fill: white;
  }

  .banner-link {
    font-weight: 500;
    color: @primary-color;

    text-decoration: underline;
    line-height: 120%;
  }
}