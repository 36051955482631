.step-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    border-radius: 50%;
    border: 2px solid @background-color_50;
    background-color: @background-color_25;
    color: @secondary_50;

    font-weight: 600;

    &.-active {
        animation: radiate 150ms ease-in-out forwards;
        background-color: white;
    }

    &.-finished {
        background-color: @secondary-color;
    }

    &.-finished span svg {
        fill: white;
    }
}

@keyframes radiate {
    from {
        border-radius: 50%;
        border: 2px solid @background-color_50;
        color: @secondary_50;
    }

    to {
        border: 2px solid @secondary-color;
        outline: 4px solid #E2E4FB;
        color: @secondary-color;
    }
}