.tooltip-title.ant-page-header {
  padding: 0 0 16px 0;

  .ant-page-header-heading-title {
    color: @secondary-color;
    font-weight: 600;
    font-size: @font-size-base + 1;
    line-height: 1.5;
  }
}

.tooltip-link {
  > .ant-space-item {
    padding: 4px 0;
  }
}

.tooltip-link > .ant-space-item > a {
  position: relative;

  margin-left: 16px;

  color: @primary-color;
  font-size: @font-size-base + 1;

  &::before {
    position: absolute;
    top: 50%;
    left: -16px;

    content: '';
    display: flex;
    width: 4px;
    height: 4px;
    background-color: @primary-color;
    border-radius: 50%;

    transform: translateY(-50%);
  }
}

.tooltip-link > .ant-space-item > a:hover {
    color: @secondary-color;

    &::before {
        background-color: @secondary-color;
    }
}
