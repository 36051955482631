@modal-font-size: 14px;

.terms-style() {
  * {
    line-height: 1.75;
    letter-spacing: 0.5px;
  }

  a {
    color: @secondary-color;
    font-weight: 600;
    text-decoration: underline;
  }

  ol > li > span,
  div > ul > li > span {
    display: block;
    margin-bottom: 12px;

    color: @primary-color;
    font-weight: 600;
  }

  ol > li::marker {
    color: @primary-color;
    font-weight: 600;
  }

  div > ol > li:not(:first-of-type) {
    margin-top: 24px;
  }

  div > ol {
    padding-left: 24px;
  }

  div > ol > li ol {
    padding-left: 24px;
  }

  div > ol > li > ol > li {
    margin-bottom: 12px;
  }

  div > ul {
    list-style-type: none;
    padding-left: 16px;
  }
}

.terms.ant-modal {
  font-size: @modal-font-size;

  .ant-modal-title .ant-col.title {
    color: @secondary-color;
    font-size: 18px;
  }

  .terms-skip {
    padding: 3.2px 16px;
    max-height: 28px;
    opacity: 0;
    cursor: default;
  }

  .ant-modal-title .ant-col.last-updated {
    display: flex;
    align-items: center;
    margin-top: 4px;

    color: @secondary_75;
    font-size: 10px;
  }

  .ant-modal-title .ant-col.last-updated span {
    display: inline-block;
    line-height: 1;

    text-transform: uppercase;
  }

  .ant-modal-title .ant-col.last-updated > strong {
    margin-left: 8px;
    line-height: 1;

    color: @secondary-color;
    font-size: 14px;
  }

  .ant-modal-content {
    display: flex;
    flex-flow: column;

    max-height: 704px;
  }

  .ant-modal-body > div {
    overflow-y: scroll;
    max-height: 452px;

    font-size: @modal-font-size;
    line-height: 140%;
  }

  .ant-modal-footer {
    background-color: @background-color_10;
  }

  .ant-form label {
    font-size: @modal-font-size;
    line-height: 140%;
  }

  .ant-modal-footer span {
    text-align: left;
  }

  .terms-style();
}

.terms.general-settings {
  overflow-y: scroll;
  max-height: calc(100vh - 210px);

  .terms-style();
}

.term-info {
  padding: 20px 16px;
  background-color: @background-color_10;
  border: 1px solid @background-color_25;
  border-radius: 6px;

  #signee {
    margin-left: 12px;
    font-weight: 500;
    font-size: 14px;

    color: @primary-color;
  }

  strong {
    font-size: 14px;
  }
}

.terms-icon > path {
  fill: rgb(74, 74, 104);
}

.terms-icon {
    margin-right: 10px;
}

.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-item-active.ant-menu-item-selected {
  > span > .terms-icon > path {
    fill: @primary-color;
    transition: fill 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.custom-content .ant-form-item-explain-error {
  margin: 6px 0 0 0;
}