.drawer-label {
    color: @secondary-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
}

.bulk-upload {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 64px;

    background-color: @background-color_25;

    border: 1px dashed #D2D2D9;
    border-radius: 4px;

    color: @text-color;
    font-weight: 400;
    cursor: pointer;

    *:first-of-type {
        font-weight: 500;
        font-size: 16px;
    }

    span:last-child {
        font-size: 12px;
    }
}

.csv-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    padding: 10px 8px;

    box-shadow: 0px 1px 2px rgba(74, 74, 104, 0.1);
    border-radius: 4px;

    >.csv-title {
        display: flex;
        align-items: center;
        gap: 8px;

        line-height: 1;
    }

    >.csv-title>span {
        color: @secondary-color;
        font-weight: 500;
        font-size: 0.75rem;

        max-width: 40ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .paper-clip {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75rem;
        height: 1.75rem;

        border-radius: 4px;
        background-color: @primary-color;
    }

    .paper-clip>span[aria-label="paper-clip"]>svg[data-icon="paper-clip"] {
        fill: white;
    }

    .csv-tag>.ant-tag {
        margin-right: 0;
    }

    .csv-approved {
        color: #007B65;
        border-radius: 4px;
        border: 1px;
        border-style: solid;
        border-color: @background-color_75;
        padding: 5px 16px;
        margin-left: 25px;
    }

    .csv-delete {
        position: absolute;
        right: -56px;

        text-decoration: underline;
        text-transform: capitalize;
        color: @primary-color;
        cursor: pointer;
        user-select: none;

        transition: color 200ms;
    }
}

.csv-delete {

    &:hover,
    &:active {
        color: @secondary-color;
    }
}

.csv-error>.ant-col>.ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 8px;
    max-height: 1rem;
    min-height: 1rem;
}

.csv-error>.ant-row {
    margin-bottom: 8px;
}

.csv-message {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;

    margin-top: 8px;
    background-color: @background-color_25;
    border-radius: 4px;

    div:first-of-type {
        max-width: 40ch;

        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
    }

    div:last-child {
        color: @primary-color;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        user-select: none;

        margin-left: 0.75rem;

        transition: color 200ms;
    }
}

.csv-message div:last-child {

    &:hover,
    &:active {
        color: @secondary-color;
    }
}

.email-list {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: @background-color_25;

    font-size: 14px;
    line-height: 1.5;
    color: @secondary-color;
}

.bulk-rewards-tabs {
    .ant-tabs-content-holder {
        max-height: 400px;
        overflow-y: scroll;

        margin-top: 16px;
    }
}

.user-detail {
    border-radius: 4px;
    padding: 8px 16px;
    background-color: @background-color_25;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.delete {
    height: 35px;
    width: 35px;
}