.select-option-extra {
  display: flex;
  cursor: pointer;
  padding: 8px 12px;
  background-color: transparent;
  width: 100%;
  border: 0;
  justify-content: space-between;
  color: @text-color;

  &:hover, &.-active {
    outline: 0;
    border: 0;
    background-color: @background-color_75;
  }

  & > .text {
    text-align: left;
    margin-right: auto;
    margin-bottom: 0;
  }

  & > .icon + .text {
    margin-left: 8px;
  }

  & > .help {
    font-size: @font-size-sm;
    opacity: 0.8;
    margin: 4px 0 4px 16px;
    flex: 0 0 auto;
    line-height: 1em;
  }
}