.ant-alert.card-expired {
  border-radius: 4px;
  padding: 2px 8px;
}

.ant-alert.expiry-warning {
  position: absolute;
  left: 0;
  top: 64px;
  z-index: -1;

  width: 100%;
  text-align: center;
  border: none;
  background-color: #ffe58f;
  border-radius: 0;
  border-left: 1px solid white;

  animation: slideDown 150ms ease-in forwards;

  @keyframes slideDown {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
