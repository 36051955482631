//* USAGE GUIDE:
//* Just add the type="card" property in the <Tabs /> component

.ant-tabs-card {
  width: 100%;

  > .ant-tabs-nav,
  > .ant-tabs-nav > .ant-tabs-nav-wrap,
  > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list,
  > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    width: inherit;
  }

  //this is the ellipsis that appears if there are too many tabs

  > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    align-items: flex-end;
    height: 60px;
  }

  > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab > .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
    width: inherit;
    height: inherit;
  }

  > .ant-tabs-nav > .ant-tabs-nav-operations {
    display: none;
  }
}

.ant-tabs-card.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
  position: relative;

  flex: 1 1 0;
  height: 40px;
  border-radius: @border-radius-md @border-radius-md 0 0;
  border: 0;
  margin: 0 !important;

  font-weight: 600;

  overflow: hidden;
  transition: all 50ms cubic-bezier(0.645, 0.045, 0.355, 1);

  > .ant-tabs-tab-btn {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    width: inherit;
    height: inherit;
    border-radius: @border-radius-md @border-radius-md 0 0;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid @background-color_50;
    border-left: 1px solid transparent;
    padding: 1rem;
    box-sizing: border-box;
    background-color: @background-color_25;

    font-weight: 600;
    font-size: @navigation-font-size;
    color: @subtitle-color;
    letter-spacing: @letter-spacing-thead;
    line-height: 140%;
    text-transform: uppercase;
    white-space: pre-wrap;

    transition: all 50ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  > .ant-tabs-tab-btn > a,
  > .ant-tabs-tab-btn > span {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    padding-left: 16px;
    border: 0;
    width: 100%;
    height: 100%;

    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    text-transform: inherit;
    white-space: inherit;
  }

  // > .ant-tabs-tab-btn:hover,
  // .ant-tabs-tab-btn > a:hover,
  // .ant-tabs-tab-btn > span:hover {
  //   color: @text-color;
  // }
}

.ant-tabs-card.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
  height: 56px;
  border-radius: @border-radius-md @border-radius-md 0 0;

  box-shadow: 0px -1px 2px rgba(219, 217, 223, 0.15),
    -1px 0px 2px rgba(219, 217, 223, 0.15),
    1px 0px 2px rgba(219, 217, 223, 0.15);

  > .ant-tabs-tab-btn {
    color: @primary-color;
    background-color: white;
    border-radius: @border-radius-md @border-radius-md 0 0;

    border-top: 1px solid @background-color_50;
    border-left: 1px solid @background-color_50;
    border-right: 1px solid @background-color_50;
    border-bottom: 1px solid transparent;
  }

  > .ant-tabs-tab-btn > a,
  > .ant-tabs-tab-btn > span {
    border: 0;
  }
}

// // .app-settings is a custom class for reinforcing style exclusivity
// .app-settings .ant-tabs-tab.ant-tabs-tab-active, .app-settings .ant-tabs-tab {
//   max-width: 25%;
// }
